import { NgModule } from '@angular/core';
import { QuizComponent } from './quiz.component';
import { QuizQuestionComponent } from './quiz-question/quiz-question.component';
import { RouterModule } from '@angular/router';
import { QuizMessageComponent } from './quiz-message/quiz-message.component';
import { CommonModule } from '@angular/common';
import { QuizRoutingModule } from './quiz-routing.module';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '../shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forChild(),
    SharedModule
  ],
  declarations: [
    QuizComponent,
    QuizQuestionComponent,
    QuizMessageComponent,
  ],
  exports: [
    QuizComponent,
    QuizQuestionComponent,
    QuizRoutingModule
  ],
  providers: [],
})
export class QuizModule { }
