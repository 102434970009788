import { NgModule } from '@angular/core';
import { Routes, RouterModule, RouteReuseStrategy } from '@angular/router';
import { QuizQuestionComponent } from './quiz-question/quiz-question.component';
import { QuizMessageComponent } from './quiz-message/quiz-message.component';
import { QuizComponent } from './quiz.component';
import { CustomReuseStrategy } from '../../route-reuse-strategy';
import { CanDeactivateComponentGuard } from '../../guards/can-deactivate-component.guard';

const routes: Routes = [
  {
    path: '',
    component: QuizComponent,
    children: [
      {
        component: QuizQuestionComponent,
        path: 'question/:id/:type',
        data: { reuseRoute: true },
        canDeactivate: [CanDeactivateComponentGuard]
      },
      {
        component: QuizMessageComponent,
        path: 'message',
        data: { reuseRoute: false },
      }
    ]
  }
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [
    {
      provide: RouteReuseStrategy,
      useClass: CustomReuseStrategy
    }
  ],
})
export class QuizRoutingModule { }
