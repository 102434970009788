<div *ngIf="activeQuestion"
     class="question-container">
  <div class="question-media" *ngIf="activeQuestion.image as image">
    <img [src]="image" alt="Question image">
  </div>
  <div class="question-media" *ngIf="activeQuestion.video as video">
    <app-video-replay [video]="video"
                      [useCenteredVideo]="false"
                      [useCustomControls]="true">
    </app-video-replay>
  </div>
  <div class="question">
    {{ activeQuestion.text }}
    <div class="info" *ngIf="activeQuestion.questionType === TYPE_OF_SKILLS_QUESTION.multipleAnswersQuestion">
      ({{ 'BUSINESS_TEST.MAX_NUMBER_OF_ANSWERS' | translate: {maxNumber: activeQuestion.maxNumberOfAnswersCandidateCanChoose} }})
    </div>
  </div>
  <div class="answers-container">
    <form [formGroup]="freeAnswerForm" class="free-answer-form" *ngIf="activeQuestion.questionType === TYPE_OF_SKILLS_QUESTION.freeAnswerQuestion">
      <div class="form-group">
        <textarea [formControl]="freeAnswer"
                  rows="9"
                  class="form-control"
                  [ngClass]="{
                      'is-invalid': freeAnswer.errors?.required && freeAnswer.touched
                    }">
        </textarea>
        <div *ngIf="freeAnswer.errors?.required && freeAnswer.touched" class="invalid-feedback">
          {{ 'SHARED.REQUIRED_ERROR_MESSAGES' | translate }}
        </div>
      </div>
    </form>
    <ul class="answers-list">
      <li
        *ngFor="let answer of activeQuestion.answers; let i = index"
        class="list-item"
      >
        <button
          (click)="selectAnswer(i)"
          [ngClass]="{ active: activeQuestion.questionType === TYPE_OF_SKILLS_QUESTION.multipleAnswersQuestion ? selectedAnswers.includes(i) : i === selectedAnswer }"
          [disabled]="!enableButtons"
          class="btn-answer"
        >
          {{ answer.text }}
        </button>
      </li>
    </ul>
  </div>
  <div class="button-container"
       *ngIf="activeQuestion.questionType === TYPE_OF_SKILLS_QUESTION.multipleAnswersQuestion ||
              activeQuestion.questionType === TYPE_OF_SKILLS_QUESTION.freeAnswerQuestion">
    <button (click)="activeQuestion.questionType === TYPE_OF_SKILLS_QUESTION.multipleAnswersQuestion ?
                     onSubmitBusinessQuestionAnswer() : onSubmitFreeAnswerBusinessQuestionAnswer()"
            class="btn-primary">
      {{ 'BUTTONS.SUBMIT' | translate }}
    </button>
  </div>
</div>
