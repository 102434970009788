import { Component, OnInit, HostBinding } from '@angular/core';
import { QuizStoreService } from 'src/app/services/quiz-store.service';
import { Router } from '@angular/router';
import { slideInAnimation } from 'src/app/router-animations';

@Component({
  selector: 'app-quiz-message',
  templateUrl: './quiz-message.component.html',
  styleUrls: ['./quiz-message.component.scss'],
  animations: [
    slideInAnimation
  ]
})
export class QuizMessageComponent implements OnInit {
  @HostBinding('class.route-card') card = true;
  @HostBinding('class.last-card') lastCard = true;
  userName: string;
  message: string;
  title: string;
  constructor(
    private router: Router,
    private quizStore: QuizStoreService
  ) { }

  ngOnInit(): void {
    if (this.quizStore.quiz) {
      this.message = this.quizStore.quiz.rejectionMessageMain;
      this.title = this.quizStore.quiz.rejectionMessage;
    }
  }

  backToJobList(): void {
    this.quizStore.reset();
    sessionStorage.removeItem('jobId');
    sessionStorage.removeItem('companyId');
    sessionStorage.removeItem('companyDomain');
    sessionStorage.removeItem('jobCompanyLogo');
    this.router.navigate(['/jobs']);
  }

}
