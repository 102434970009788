<div *ngIf="title"
     class="message-greeting">
  {{ title }}
</div>
<div *ngIf="message"
     class="message-content">
  {{ message }}
</div>
<button (click)="backToJobList()"
        class="btn-primary">{{ 'BUTTONS.BACK_TO_AUDITIONS_LIST' | translate }}</button>
